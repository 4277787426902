<template>
  <div>
    <form
        @submit.prevent="!trainingCertification_id ? saveTrainingCertification() : Update(trainingCertification_id)"
        @keydown="form.onKeydown($event)"
    >
      <h3 class="card-title">
        Manage Training & Certification :
      </h3>
      <div>
        <h2 class="pb-2" v-if="title">{{ title }}</h2>
        <p class="pb-2" v-if="description">{{ description }}</p>
      </div>
      <b-row>
        <b-col lg="8">
          <b-row>
            <b-col cols="12" lg="6">
              <label>Institution*</label>
              <Input
                  v-model="form.institution"
                  placeholder="Enter Your Institution Name"
                  style="width: 100%"
                  type="text"
              />
              <has-error :form="form" field="institution"></has-error>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Topic</label>
                <Input
                    v-model="form.topic"
                    placeholder="Enter Your Topic"
                    style="width: 100%"
                    type="text"
                />
                <has-error :form="form" field="topic"></has-error>
              </div>
            </b-col>

            <b-col lg="12">
              <b-row>
                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label>Starting Date*</label>
                    <DatePicker
                        v-model="starting_date"
                        style="width: 100%"
                        type="date"
                        placeholder="Select Starting date"
                    />
                    <has-error :form="form" field="starting_date"></has-error>
                  </div>
                </b-col>

                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label v-if="form.is_running">Approximate Ending Date</label>
                    <label v-else>Ending Date</label>
                    <DatePicker
                        v-model="ending_date"
                        type="date"
                        :placeholder="form.is_running ? 'Select Approximate Ending date' : 'Select Ending date'"
                        style="width: 100%"
                    />
                    <has-error :form="form" field="ending_date"></has-error>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <div class="form-group text-left mb-1">
                <p>
                  <Checkbox border v-model="form.is_running">
                    &nbsp;Running
                  </Checkbox>
                </p>
                <has-error :form="form" field="is_running"></has-error>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" cols="12">
          <div class="form-group">
            <label>Course</label>
            <Select v-model="form.course_id" filterable>
              <Option
                  v-if="courses.length"
                  v-for="course in courses"
                  :value="course.id"
                  :key="course.id"
              >{{ course.name }}
              </Option>
            </Select>
            <has-error :form="form" field="courses"></has-error>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 text-right">
        <b-row class="pt-1">
          <b-col class="d-flex justify-content-between" cols="4" lg="6">
            <b-button
                variant="primary"
                v-if="trainingCertifications.length > 0 && nextName && !profile"
                @click="nextOnboard(nextName, nextTitle)"
            >
              Next
            </b-button>
            <router-link :to="'/profile/' + $route.params.id" v-if="profile">
              <b-button
                  variant="primary"
                  class="ml-1">
                Back to profile
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="8" lg="6">
            <b-button variant="danger" @click="clear()">
              Clear
            </b-button>
            <b-button variant="primary" class="ml-1" type="submit" :disabled="form.busy">
              {{ trainingCertification_id ? "Update" : "Add" }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </form>
    <hr class="mt-2"/>

    <div
        class="card border"
        v-for="(trainingCertification, index) in trainingCertifications"
        v-if="trainingCertifications.length"
        :key="index"
    >
      <div class="card-body">
        <b-row>
          <b-col
              cols="12"
              lg="12"
              class="d-flex justify-lg-content-between flex-column flex-lg-row"
          >
            <div class="order-1 order-lg-0">
              <h5 class="card-title font-weight-bolder font-weight-bold">
                {{ trainingCertification.institution }}
              </h5>
            </div>
            <div class="order-0 order-lg-1 ml-auto">
              <button
                  class="btn btn-primary btn-sm"
                  @click="edit(trainingCertification.id)"
              >
                <feather-icon icon="EditIcon"/>
              </button>
              <button
                  class="btn btn-danger btn-sm ml-1"
                  @click="showConfirmation(trainingCertification.id)"
              >
                <feather-icon icon="TrashIcon"/>
              </button>
            </div>
          </b-col>

          <b-col cols="12 mb-1" v-if="trainingCertification.course.name ">
            <div>
              <h6 class="card-subtitle text-secondary"><b>Course name</b> : {{ trainingCertification.course.name }}</h6>
            </div>
          </b-col>
          <b-col cols="12" v-if="trainingCertification.topic">
            <h6 class="card-subtitle mb-1 text-secondary">
              <b>Topic name</b> : {{ trainingCertification.topic }}
            </h6>
          </b-col>
          <b-col cols="12" v-if="trainingCertification.duration">
            <h6 class="card-subtitle mb-1 text-secondary">
              <b>Duration</b> : {{ trainingCertification.duration }} Days
            </h6>
          </b-col>
          <b-col cols="12" :class="!trainingCertification.is_running ? '': 'mb-1'">
            <div class="d-flex">
              <div>
                <h6 class="card-subtitle text-secondary">
                  {{ trainingCertification.starting_date | date_format }} -
                  {{ trainingCertification.ending_date | date_format }}
                </h6>
              </div>
              <div class="ml-1">
                <h6 class="card-subtitle text-primary" v-if="trainingCertification.is_running ">
                  (Running)
                </h6>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow,} from "bootstrap-vue";
import {AlertError, HasError} from "vform/src/components/bootstrap5";

export default {
  props: [
    "title",
    "nextOnboard",
    "nextTitle",
    "nextName",
    "description",
    "profile",
  ],
  data() {
    return {
      form: new Form({
        course_id: null,
        topic: null,
        institution: null,
        starting_date: null,
        ending_date: null,
        is_running: false,
      }),
      courses: null,
      trainingCertification_id: null,
      starting_date: null,
      ending_date: null,
      trainingCertifications: [],
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    this.showTrainingCertification();
    this.getCourse();
  },
  methods: {
    clear() {
      this.trainingCertification_id = null;
      this.form.course_id = null;
      this.form.topic = null;
      this.form.institution = null;
      this.form.starting_date = null;
      this.form.ending_date = null;
      this.form.is_running = false;
      this.starting_date = null;
      this.ending_date = null;
    },

    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    saveTrainingCertification() {
      this.form.starting_date = this.formatDate(this.starting_date);
      this.form.ending_date = this.formatDate(this.ending_date);
      this.form
          .post("/app/candidate/training-certification/" + this.$route.params.id)
          .then((response) => {
            this.s(response.data.message);
            this.clear();
            this.$store.dispatch("onboardData");
            this.showTrainingCertification();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    edit(id) {
      axios
          .get("/app/candidate/training-certification/" + id + "/edit")
          .then((res) => {
            this.trainingCertification_id = res.data.trainingCertification.id;
            this.form.course_id = res.data.trainingCertification.course_id;
            this.form.institution = res.data.trainingCertification.institution;
            this.form.topic = res.data.trainingCertification.topic;
            this.form.starting_date = res.data.trainingCertification.starting_date;
            this.form.ending_date = res.data.trainingCertification.ending_date;
            this.starting_date = res.data.trainingCertification.starting_date;
            this.ending_date = res.data.trainingCertification.ending_date;
            this.form.is_running = res.data.trainingCertification.is_running == 1 ? true : false;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    Update(id) {
      this.form.starting_date = this.formatDate(this.starting_date);
      this.form.ending_date = this.formatDate(this.ending_date);
      this.form
          .put("/app/candidate/training-certification/" + id)
          .then((res) => {
            this.s(res.data.message);
            this.clear();
            this.showTrainingCertification();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    destroy(id) {
      axios
          .delete("/app/candidate/training-certification/" + id)
          .then((response) => {
            this.s(response.data.message);
            this.showTrainingCertification();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    getCourse() {
      axios
          .get("/app/get/all-course")
          .then((res) => {
            this.courses = res.data.courses;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showTrainingCertification() {
      axios
          .get("/app/candidate/training-certification/" + this.$route.params.id)
          .then((res) => {
            this.trainingCertifications = res.data.trainingCertifications;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showConfirmation(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to Delete!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(id);
        }
      })
    },
  },
};
</script>

<style scoped>
.onboard-form .card-body {
  display: block !important;
}
</style>
