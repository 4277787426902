<template>
    <div>
      <form
        @submit.prevent="!education_id ? saveEducation() : Update(education_id)"
        @keydown="form.onKeydown($event)"
      >
        <h3 class="card-title">
          Manage Education :
        </h3>

        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col cols="12" lg="6">
                <label>Education Level*</label>
                <Select v-model="form.level_id" filterable>
                  <Option
                      v-if="levels.length"
                      v-for="level in levels"
                      :value="level.id"
                      :key="level.id"
                  >{{ level.name }}
                  </Option
                  >
                </Select>
                <has-error :form="form" field="level_id"></has-error>
              </b-col>

              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>Degree</label>
                  <Input
                      v-model="form.degree"
                      placeholder="Enter Your Degree Name"
                      style="width: 100%"
                      type="text"
                  />
                  <has-error :form="form" field="degree"></has-error>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>GPA/CGPA</label>
                  <InputNumber
                      v-model="form.gpa"
                      placeholder="Enter Your GPA/CGPA"
                      style="width: 100%"
                      max="5"
                  />
                  <has-error :form="form" field="gpa"></has-error>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>Board</label>
                  <Select v-model="form.education_board_id" filterable>
                    <Option
                        v-if="boards.length"
                        v-for="board in boards"
                        :value="board.id"
                        :key="board.id"
                    >{{ board.name }}
                    </Option>
                  </Select>
                  <has-error :form="form" field="board"></has-error>
                </div>
              </b-col>

              <b-col lg="12">
                <b-row>
                  <b-col cols="12" lg="6">
                    <div class="form-group">
                      <label>Starting Date*</label>
                      <DatePicker
                          v-model="starting_date"
                          style="width: 100%"
                          type="date"
                          placeholder="Select Starting date"
                      />
                      <has-error :form="form" field="starting_date"></has-error>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="6">
                    <div class="form-group">
                      <label v-if="form.is_running">Approximate Ending Date</label>
                      <label v-else>Ending Date</label>
                      <DatePicker
                          v-model="ending_date"
                          type="date"
                          :placeholder="form.is_running ? 'Select Approximate Ending date' : 'Select Ending date'"
                          style="width: 100%"
                      />
                      <has-error :form="form" field="ending_date"></has-error>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <div class="form-group text-left mb-1">
                  <p>
                    <Checkbox border v-model="form.is_running">
                      &nbsp;Running
                    </Checkbox>
                  </p>
                  <has-error :form="form" field="is_running"></has-error>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4" cols="12">
            <div>
              <div class="form-group">
                <label>Subject</label>
                <Select v-model="form.education_subject_id" filterable>
                  <Option
                      v-if="subjects.length"
                      v-for="subject in subjects"
                      :value="subject.id"
                      :key="subject.id"
                  >{{ subject.name }}
                  </Option>
                </Select>
                <has-error :form="form" field="subject"></has-error>
              </div>
            </div>
            <div>
              <label>Institute*</label>
              <Input
                  v-model="form.institute"
                  placeholder="Enter Your Institute Name"
                  style="width: 100%"
                  type="textarea"
                  :rows="4"
              />
              <has-error :form="form" field="institute"></has-error>
            </div>
          </b-col>
        </b-row>

        <div class="w-100 text-right">
          <b-row class="pt-1">
            <b-col class="d-flex justify-content-between" cols="4" lg="6">
              <router-link :to="'/profile/' + $route.params.id" v-if="profile">
                <b-button
                variant="primary"
                class="ml-1">
                Back to profile
              </b-button>
              </router-link>
            </b-col>
            <b-col cols="8" lg="6">
              <b-button variant="primary" type="submit" :disabled="form.busy">
                {{ education_id ? "Update" : "Add" }}
              </b-button>
              <b-button variant="danger" class="ml-1" @click="clear()">
                Clear
              </b-button>
            </b-col>
          </b-row>
        </div>
      </form>
      <hr class="mt-2" />

      <div
        class="card border"
        v-for="(education, index) in educations"
        v-if="educations.length"
        :key="index"
      >
        <div class="card-body">
          <b-row>
            <b-col
                cols="12"
                lg="12"
                class="d-flex justify-lg-content-between flex-column flex-lg-row"
            >
              <div class="order-1 order-lg-0">
                <h5 class="card-title font-weight-bolder font-weight-bold">
                  {{ education.institute }}
                </h5>
              </div>
              <div class="order-0 order-lg-1 ml-auto">
                <button
                    class="btn btn-primary btn-sm"
                    @click="edit(education.id)"
                >
                  <feather-icon icon="EditIcon"/>
                </button>
                <button
                    class="btn btn-danger btn-sm ml-1"
                    @click="showConfirmation(education.id)"
                >
                  <feather-icon icon="TrashIcon"/>
                </button>
              </div>
            </b-col>

            <b-col cols="12 mb-1" v-if="education.degree || education.subject">
              <div class="d-flex">
                <div v-if="education.degree">
                  <h6 class="card-subtitle text-secondary">{{ education.degree }}</h6>
                </div>
                <div v-if="education.subject">
                  <h6 class="card-subtitle text-secondary">&nbsp;in {{ education.subject }}</h6>
                </div>
              </div>
            </b-col>
            <b-col cols="12" v-if="education.gpa">
              <h6 class="card-subtitle mb-1 text-secondary">
                GPA / CGPA : {{ education.gpa }}
              </h6>
            </b-col>
            <b-col cols="12" v-if="education.board">
              <h6 class="card-subtitle mb-1 text-secondary">
                Board : {{ education.board }}
              </h6>
            </b-col>
            <b-col cols="12" :class="!education.is_running ? '': 'mb-1'">
              <h6 class="card-subtitle text-secondary">
                {{ education.starting_date }} - {{ education.ending_date }}
              </h6>
            </b-col>
            <b-col cols="12" v-if="education.is_running">
              <h6 class="card-subtitle text-secondary">
                <span class="badge badge-primary">Running</span>
              </h6>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>

  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  } from "bootstrap-vue";
  import { HasError, AlertError } from "vform/src/components/bootstrap5";

  export default {
    props: [
      "profile",
    ],
    data() {
      return {
        form: new Form({
          candidate_id: null,
          level_id: null,
          education_subject_id: null,
          education_board_id: null,
          degree: null,
          institute: null,
          gpa: null,
          starting_date: null,
          ending_date: null,
          is_running: false,
        }),
        education_id: null,
        levels: [],
        boards: [],
        subjects: [],
        starting_date: null,
        ending_date: null,
        educations: [],
      };
    },
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BForm,
      BButton,
      HasError,
      AlertError,
    },
    mounted() {
      this.showEducation();
      this.getLevel();
      this.getSubject();
      this.getBoard();
    },
    methods: {
      clear() {
        this.education_id = null;
        this.form.level_id = null;
        this.form.education_board_id = null;
        this.form.education_subject_id = null;
        this.form.degree = null;
        this.form.institute = null;
        this.form.gpa = null;
        this.form.starting_date = null;
        this.starting_date = null;
        this.ending_date = null;
        this.form.ending_date = null;
        this.form.is_running = false;
      },

      formatDate(date) {
        if (date) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        }
        return null;
      },
      getLevel() {
        axios
          .get("/app/candidate/get/level")
          .then((res) => {
            this.levels = res.data.levels;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      getBoard() {
        axios
            .get("/app/candidate/get/board")
            .then((res) => {
              this.boards = res.data.boards;
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      },
      getSubject() {
        axios
            .get("/app/candidate/get/subject")
            .then((res) => {
              this.subjects = res.data.subjects;
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      },
      saveEducation() {
        this.form.starting_date = this.formatDate(this.starting_date);
        this.form.ending_date = this.formatDate(this.ending_date);
        this.form.candidate_id = this.$route.params.id;
        this.form
          .post("/app/candidate/add/education/"+ this.$route.params.id)
          .then((response) => {
            this.s(response.data.message);
            this.clear();
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      edit(id) {
        axios
          .get("/app/candidate/edit/education/" + id + '/' + this.$route.params.id)
          .then((res) => {
            this.education_id = res.data.education.id;
            this.form.level_id = res.data.education.level_id;
            this.form.degree = res.data.education.degree;
            this.form.institute = res.data.education.institute;
            this.form.gpa = res.data.education.gpa;
            this.form.education_subject_id = res.data.education.education_subject_id;
            this.form.education_board_id = res.data.education.education_board_id;
            this.starting_date = res.data.education.starting_date;
            this.ending_date = res.data.education.ending_date;
            this.form.is_running = res.data.education.is_running == 1 ? true : false;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      Update(id) {
        this.form.starting_date = this.formatDate(this.starting_date);
        this.form.ending_date = this.formatDate(this.ending_date);
        this.form.candidate_id = this.$route.params.id;
        this.form
          .put("/app/candidate/update/education/" + id +'/' + this.$route.params.id)
          .then((res) => {
            this.s(res.data.message);
            console.log(this.profile)
            this.clear();
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      destroy(id) {
        axios
          .delete("/app/candidate/delete/education/" + id + '/' + this.$route.params.id)
          .then((response) => {
            this.s(response.data.message);
            this.showEducation();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },
      showEducation() {
        axios
          .get("/app/candidate/get/education/" + this.$route.params.id)
          .then((res) => {
            this.educations = res.data.data;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
      },

      showConfirmation(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You want to Delete!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.destroy(id);
          }
        })
      },
    },
  };
  </script>

  <style scoped>
  .onboard-form .card-body {
    display: block !important;
  }
  </style>