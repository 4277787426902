<template>
  <b-card>
    <BasicInfo profile="true" v-if="$route.params.slug == 'basic-info'"/>
    <Education profile="true" v-else-if="$route.params.slug == 'education'"/>
    <Cv profile="true" v-else-if="$route.params.slug == 'cv'"/>
    <Experience profile="true" v-else-if="$route.params.slug == 'experience'"/>
    <Preference profile="true" v-else-if="$route.params.slug == 'preference'"/>
    <Skill profile="true" v-else-if="$route.params.slug == 'skill'"/>
    <Curriculum profile="true" v-else-if="$route.params.slug=='curriculum-activities'"/>
    <TrainingCertification profile="true" v-else-if="$route.params.slug=='training-certification'"/>
  </b-card>
</template>

<script>
import {BCard} from "bootstrap-vue";
import BasicInfo from "@/views/candidate/partials/BasicInfo.vue";
import Cv from "@/views/candidate/partials/Cv.vue";
import Experience from "@/views/candidate/partials/Experience.vue";
import Preference from "@/views/candidate/partials/Preference.vue";
import Skill from "@/views/candidate/partials/Skill.vue";
import Education from "@/views/candidate/partials/Education.vue";
import Curriculum from "@/views/candidate/partials/Curriculum.vue";
import TrainingCertification from "@/views/candidate/partials/TrainingCertification.vue";

export default {
  props: ["candidate_id"],
  components: {
    TrainingCertification,
    BCard,
    BasicInfo,
    Cv,
    Experience,
    Preference,
    Skill,
    Education,
    Curriculum
  },
};
</script>
